import React from "react";
import logo from "../assets/logo/vpbank.svg";
import logofimi from "../assets/Logo FiMi 4.svg";
const Header = () => {
  return (
    <div className="bg-white h-24 ">
      <div className="bg-white h-24 flex items-center justify-between px-7 mb-1 pb-5">
        <img className=" w-36 h-28" src={logofimi} alt="logo" />
        <img className=" w-36 h-16 mr-4" src={logo} alt="logo" />
      </div>
    </div>
  );
};

export default Header;
