import React from 'react';

const Copyright = () => {
  return (
    <div className="h-14 bg-gradient-to-r from-red-900 to-red-700 flex flex-col justify-center items-center">
      <p className="text-white text-xs">FIMI - Bản quyền đã được bảo hộ</p>
      <p className="text-white text-xs">Chính sách bảo mật</p>
    </div>
  );
};

export default Copyright;
