import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchCities } from "../services/locationService";
import {
  getLead,
  getRelationships,
  getUpdateToLOS,
  Reference_info,
} from "../services/apiService";
import LoadingOverlay from "../components/Loading";
import Swal from "sweetalert2";
import { get } from "lodash";
const Contact = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [provinces, setProvinces] = useState([]);
  const [cardTypes, setCardTypes] = useState("");
  const [relationships, setRelationships] = useState([]);
  const [errors, setErrors] = useState({});
  const [loadings, setLoadings] = useState(false);
  const id = localStorage.getItem("id");
  const userId = localStorage.getItem("userId");
  const finalId = id !== null ? id : userId;
  const [kycSegment, setKycSegment] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadings(true);
        const leadResponse = await getLead(finalId);
        const { kyc_segment } = leadResponse.data.data;
        setKycSegment(kyc_segment);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadings(false);
      }
    };
  
    fetchData();
  }, [finalId]); 
  console.log(kycSegment);
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    relationships: "",
    security_question: "Trường tiểu học bạn là gì?",
    security_answer: "",
    shipping_address: "",
    fatca: "",
    tin_number: "",
  });
  const [selectedOption, setSelectedOption] = useState("no");

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value); 
    setFormData((prevData) => ({
      ...prevData,
      fatca: event.target.value === "yes" ? "Y" : "N",
      tin_number: event.target.value === "no" ? "" : prevData.tin_number,
    }));
  };

  useEffect(() => {
    setLoadings(true);
    fetchCities()
      .then((response) => {
        setProvinces(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);
  useEffect(() => {
    setLoadings(true);
    getLead(finalId)
      .then((response) => {
        setCardTypes(response.data.data.card_type);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, [finalId]);

  useEffect(() => {
    setLoadings(true);

    getRelationships()
      .then((response) => {
        setRelationships(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);
  const handleSubmit = () => {

    const newErrors = {};
    if (!formData.phone) newErrors.phone = "Vui lòng nhập số điện thoại.";
    if (!formData.relationships)
      newErrors.relationships = "Vui lòng chọn mối quan hệ.";
    if (!formData.security_question)
      newErrors.security_question = "Vui lòng nhập câu hỏi bảo mật.";
    if (!formData.security_answer)
      newErrors.security_answer = "Vui lòng nhập câu trả lời.";
    if (!formData.shipping_address)
      newErrors.shipping_address = "Vui lòng nhập địa chỉ nhận.";
    if (selectedOption === "yes" && !formData.tin_number) {
      newErrors.tin_number = "Vui lòng nhập số nhận dạng thuế.";
    }
    setErrors(newErrors);


    if (Object.keys(newErrors).length === 0) {
      setLoadings(true);
      const dataStatus = {
        reference_person: [
          {
            reference1_fullname: formData.fullName,
            reference1_mobile_phone_number: formData.phone,
            reference1_relationship: formData.relationships,
          },
        ],
        card_delivery_address: formData.shipping_address,
        security_answer: formData.security_answer,
        security_question: formData.security_question,
        fatca: formData.fatca || "N",
        tin_number: formData.tin_number,
      };
      Reference_info(finalId, dataStatus)
        .then((response) => {
          return Promise.all([getUpdateToLOS(finalId)]);
        })
        .then(([]) => {})
        .then(() => {
          Swal.fire({
            title: "Hoàn thành đăng ký",
            text: "Thẻ sẽ được gửi đến tay của bạn!",
            icon: "success",
          }).then(() => {
            if (String(get(kycSegment)).toUpperCase() === "ETB_NON_VERIFIED" || String(get(kycSegment)).toUpperCase() === "NTB") {
              navigate(`/vpbank-creditcard/guidevkyc${location.search}`);
            } else {
              navigate(`/vpbank-creditcard/econtract${location.search}`);
            }
          });
        })
        .catch((error) => {
          console.error("Cập nhật thất bại", error);
          Swal.fire({
            title: "Cập nhật thất bại",
            text: "",
            icon: "error",
          });
        })
        .finally(() => setLoadings(false));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  return (
    <div className="flex flex-col items-center p-4 bg-white">
      {loadings && <LoadingOverlay />}

      <div className="flex items-center mt-2 ml-2">
        <p className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-500 mr-6 w-[60%]">
          Thông tin người thân và địa chỉ phát thẻ
        </p>
        <img
          src={require("../assets/contact1.png")}
          className="w-24 h-[100px]"
          alt="Parent"
        />
      </div>

      <div className="h-[2.5px] bg-red-700 w-full mt-8" />
      <div className="mt-8 w-full max-w-md bg-[#850101] rounded-2xl p-4 custom-shadow">
        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Họ và tên người thân cần liên hệ:
          </label>
          <input
            name="fullName"
            onChange={handleChange}
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
            placeholder="Nhập họ và tên"
            type="text"
            value={formData.fullName}
          />
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Mối quan hệ:
          </label>
          <select
            name="relationships"
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
            value={formData.relationships}
            onChange={handleChange}
          >
            <option value="" disabled selected>
              Chọn mối quan hệ
            </option>
            {relationships.map((relationship, key) => (
              <option value={relationship.code}>{relationship.name}</option>
            ))}
          </select>
          {errors.relationships && (
            <p className="text-red-500 text-xs mt-1">{errors.relationships}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Số điện thoại:
          </label>
          <input
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="h-10 rounded-md text-sm px-2 bg-white w-full"
            placeholder="Nhập số điện thoại"
            type="tel"
            pattern="[0-9]*"
            inputMode="numeric"
            maxLength={10}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
          />
          {errors.phone && (
            <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Câu hỏi bảo mật:
          </label>
          <input
            name="security_question"
            value={formData.security_question}
            onChange={handleChange}
            readOnly
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
            placeholder="Nhập câu hỏi"
            type="text"
          />
          {errors.security_question && (
            <p className="text-red-500 text-xs mt-1">
              {errors.security_question}
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Câu trả lời:
          </label>
          <input
            name="security_answer"
            value={formData.security_answer}
            onChange={handleChange}
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
            placeholder="Nhập câu trả lời"
            type="text"
          />
          {errors.security_answer && (
            <p className="text-red-500 text-xs mt-1">
              {errors.security_answer}
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Địa chỉ nhận:
          </label>
          <select
            name="shipping_address"
            value={formData.shipping_address}
            onChange={handleChange}
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
          >
            <option value="" disabled>
              Chọn địa chỉ
            </option>
            <option value="COADDR">Nơi làm việc</option>
            <option value="CUADDR">Nơi ở hiện tại</option>
          </select>
          {errors.shipping_address && (
            <p className="text-red-500 text-xs mt-1">
              {errors.shipping_address}
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Khách hàng có yếu tố Hoa Kỳ hay không?
          </label>
          <div className="ml-4">
            <div className="mb-2 mt-3">
              <input
                type="checkbox"
                id="yes"
                name="us-factor"
                value="yes"
                className=" w-4 h-4 mr-2"
                checked={selectedOption === "yes"}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="yes" className="text-base text-white">
                Có
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="no"
                name="us-factor"
                value="no"
                className=" w-4 h-4 mr-2"
                checked={selectedOption === "no"} 
                onChange={handleCheckboxChange}
              />
              <label htmlFor="no" className="text-base text-white">
                Không
              </label>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-white text-sm font-normal ml-2 mb-1">
            Số nhận dạng thuế (TIN):
          </label>
          <input
            name="tin_number"
            value={formData.tin_number}
            onChange={handleChange}
            className="h-10 rounded-md px-2 text-sm bg-white w-full"
            placeholder="Nhập số nhận dạng thuế"
            type="number"
            disabled={selectedOption === "no"} 
          />
          {errors.tin_number && (
            <p className="text-red-500 text-xs mt-1">{errors.tin_number}</p>
          )}
        </div>
      </div>
      <div className="items-center justify-center mt-10 mb-10">
        <button
          onClick={handleSubmit}
          className="bg-gradient-to-r from-red-900 to-red-700 rounded-[17px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"        >
        
          <p className="text-white text-base font-semibold">Tiếp tục</p>
        </button>
      </div>
    </div>
  );
};

export default Contact;
