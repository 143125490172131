import React, {} from "react";
import sadface from "../assets/anxiety.png";

const Reject = () => {


  return (
    <div className="flex flex-col items-center p-5 bg-white h-screen">
      <h1 className="uppercase text-xl font-bold mt-6 bg-clip-text text-transparent  bg-gradient-to-r from-yellow-600 to-yellow-500 text-center">
        Hồ sơ của bạn đã bị từ chối tiếp nhận
      </h1>
      <div className="bg-white h-24 mt-3">
        <img className=" mt-5 w-36 " src={sadface} alt="sadface" />
      </div>
      <div className="mt-[95px] uppercase text-sm font-bold text-center">
        Rất tiếc hệ thống đã từ chối tiếp nhận hồ sơ của bạn, hãy quay lại sau nhé!
      </div>
      {/* <div className="items-center justify-center mt-9 mb-14">
        <button
          className="bg-gray-300 rounded-lg w-64 h-10 items-center justify-center mb-5 transition-transform duration-300 hover:scale-105 hover:bg-gray-300"  
        >
          <span className="text-gray-800 text-base font-semibold">
            Quay lại
          </span>
        </button>
      </div> */}
    </div>
  );
};

export default Reject;
