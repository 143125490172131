import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header.js';
import './style.css';
import Footer from './components/Footer.js';
import Copyright from './components/Copyright.js';
import Step1Screen from './pages/Step1Screen.js';
import Step2Screen from './pages/Step2Screen.js';
import Step3Screen from './pages/Step3Screen.js';
import Step4Screen from './pages/Step4Screen.js';
import CheckData from './pages/CheckData.js';
import ChooseProduct from './pages/ChooseProduct.js';
import Face from './pages/Face.js';
import Contact from './pages/Contact.js';
import Complete from './pages/Complete.js';
import ScrollToTop from './ScrollToTop.js';
import { UserProvider } from './UserContext.js';
import OTPScreen from './pages/OTPScreen.js';
import PageStart from './pages/PageStart.js';
import CancelCreditCard from './pages/CancelCreditCard.js';
import Econtract from './pages/Econtract.js';
import OTPScreencf from './pages/OTPScreencf.js';
import VerifyInfoBack from './pages/VerifyOCR/VerifyInfoBack.js';
import VerifyInfoFront from './pages/VerifyOCR/VerifyInfoFront.js';
import VerifyInfoQR from './pages/VerifyOCR/VerifyInfoQR.js';
import Reject from './pages/Reject.js';
import GuideVerifyInfo from './pages/VerifyOCR/GuideVerifyInfo.js';
import GuideVKYC from './pages/GuideVKYC.js';
import Waitting from './pages/Waiting.js';
import WaitingResult from './pages/WaitingResult.js';

function App() {
  return (
    <UserProvider>
      <Router>
        <div className="relative flex h-full w-full items-center justify-center bg-background">
          <div
            className="container h-[calc(100vh-10px)] grid grid-rows-[auto_1fr_auto] w-[430px] overflow-y-auto rounded-lg border-foreground/30 bg-background sm:border">
            <ScrollToTop />
            <ConditionalHeaderFooter>
              <Routes>
                <Route path="/" element={<PageStart />} />
                <Route path="/vpbank-creditcard" element={<Step1Screen />} />
                <Route path="/vpbank-creditcard/step2Screen" element={<Step2Screen />} />
                <Route path="/vpbank-creditcard/step3Screen" element={<Step3Screen />} />
                <Route path="/vpbank-creditcard/step4Screen" element={<Step4Screen />} />
                <Route path="/vpbank-creditcard/checkData" element={<CheckData />} />
                <Route path="/vpbank-creditcard/chooseProduct" element={<ChooseProduct />} />
                <Route path="/vpbank-creditcard/ocr-front" element={<VerifyInfoFront />} />
                <Route path="/vpbank-creditcard/ocr-back" element={<VerifyInfoBack />} />
                <Route path="/vpbank-creditcard/ocr-qr" element={<VerifyInfoQR />} />
                <Route path="/vpbank-creditcard/face" element={<Face />} />
                <Route path="/vpbank-creditcard/contact" element={<Contact />} />
                <Route path="/vpbank-creditcard/complete" element={<Complete />} />
                <Route path="/vpbank-creditcard/OTPScreen" element={<OTPScreen />} />
                <Route path="/vpbank-creditcard/cancelCreditCard" element={<CancelCreditCard />} />
                <Route path="/vpbank-creditcard/econtract" element={<Econtract />} />
                <Route path="/vpbank-creditcard/OTPScreencf" element={<OTPScreencf />} />
                <Route path="/vpbank-creditcard/reject" element={<Reject />} />
                <Route path="/vpbank-creditcard/guide" element={<GuideVerifyInfo />} />
                <Route path="/vpbank-creditcard/guidevkyc" element={<GuideVKYC />} />
                <Route path="/vpbank-creditcard/waiting" element={<Waitting />} />
                <Route path="/vpbank-creditcard/waitingresult" element={<WaitingResult />} />
              </Routes>
            </ConditionalHeaderFooter>
          </div>
        </div>
      </Router>
    </UserProvider>
  );
}

function ConditionalHeaderFooter({ children }) {
  const location = useLocation();
  const noHeaderFooterPaths = ['/']; 
  const shouldHideHeaderFooter = noHeaderFooterPaths.includes(location.pathname);

  const queryParams = new URLSearchParams(location.search);
  const clickId = queryParams.get('click_id'); 
  const partner = queryParams.get('partner'); 
  const affiliateCode = queryParams.get('affiliate_code');
  const info = queryParams.get('info');

  useEffect(() => {
    if (partner || affiliateCode || clickId || info) {

    } else {

    }
  }, [partner, affiliateCode, clickId, info]);


  return (
    <>
      {!shouldHideHeaderFooter && <Header />}
      {children}
      {!shouldHideHeaderFooter && (
        <>
          <Footer/>
          <Copyright />
        </>
      )}
    </>
  );
}

export default App;
