import { useLocation, useNavigate } from "react-router-dom";
const GuideVerifyInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div
      id="content"
      className="verify-info flex flex-col items-center p-5 bg-white  "
    >
      {" "}
      <div id="web-sdk-capture-front"></div>
      <h1 className="text-xl font-bold mt-4 text-center text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-500">
        Xác thực giấy tờ tuỳ thân
      </h1>
      <p className="text-xs font-normal mt-3 text-gray-600 text-center">
        Chụp 2 mặt căn cước công dân
      </p>
      <div className="h-[350px] w-[96%] bg-gray-200 rounded-3xl mt-5 items-center custom-shadow">
        <div className="flex items-center gap-4 px-4 mt-9 mb-9 ml-2">
          <img
            id="frontImage"
            src={require("../../assets/front.png")}
            alt="image1"
            className="w-[18%]"
          />
          <p className="text-xs">
            Chụp lần lượt mặt trước và mặt sau của CCCD bản gốc, còn hạn sử
            dụng.
          </p>
        </div>
        <div className="flex items-center gap-4 px-4 mb-9 ml-2">
          <img
            id="frontImage"
            src={require("../../assets/Frame 1.png")}
            alt="image2"
            className="w-[18%]"
          />
          <p className="text-xs">Ảnh chụp CCD không bị mất gốc, bấm lỗ.</p>
        </div>
        <div className="flex items-center gap-4 px-4 mb-9 ml-2">
          <img
            id="frontImage"
            src={require("../../assets/front.png")}
            alt="image3"
            className="w-[18%]"
          />
          <p className="text-xs">
            Chú ý ánh sáng, điều kiện chụp. ảnh chụp cần rõ nét, không bị mờ, bị
            tối hay chói sáng.{" "}
          </p>
        </div>
        <div className="flex items-center gap-4 px-4 mb-9 ml-2">
          <img
            id="frontImage"
            src={require("../../assets/Layer_1.png")}
            alt="image4"
            className="w-[18%]"
          />
          <p className="text-xs">
            Không chụp ảnh CCCD từ màn hình máy tính, điện thoại.
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-3"></div>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <pre id="result-extract-id-info2"></pre>
      </div>
      <div className="items-center justify-center mt-9 mb-14">
        <button
          onClick={() =>
            navigate(`/vpbank-creditcard/ocr-front${location.search}`)
          }
          className="bg-gradient-to-r from-red-900 to-red-700 rounded-[10px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"
        >
          <span className="text-white text-base font-semibold">Tiếp tục</span>
        </button>
      </div>
    </div>
  );
};

export default GuideVerifyInfo;
