import React from "react";

const Footer = () => {
  return (
    <div className="text-[12px] text-center font-light leading-tight p-6">
      Bằng việc cung cấp thông tin, bạn đã đồng ý với Điều khoản sử dụng dịch vụ
      FIMI, Chính sách bảo vệ dữ liệu cá nhân và Thông báo bảo mật của chúng
      tôi.
    </div>
  );
};

export default Footer;
