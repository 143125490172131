import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderRegister from "../components/HeaderReigister";
import LoadingOverlay  from "../components/Loading";
import {
  fetchCities,
  fetchDistricts,
  fetchWards,
} from "../services/locationService";
import { useUserContext } from "../UserContext";
import axios from "axios";
import { Residence_info } from "../services/apiService";

const Step3Screen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep] = useState(3);
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [ward, setWard] = useState("");
  const [currentProvince, setCurrentProvince] = useState("");
  const [currentDistrict, setCurrentDistrict] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [currentWard, setCurrentWard] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [permanentDistricts, setPermanentDistricts] = useState([]);
  const [permanentWards, setPermanentWards] = useState([]);
  const [currentDistricts, setCurrentDistricts] = useState([]);
  const [currentWards, setCurrentWards] = useState([]);
  const [errors, setErrors] = useState({});
  const { userInfo, setUserInfo } = useUserContext();
  const [ipAddress, setIpAddress] = useState("");

  const [loadings, setLoadings] = useState(false);
  const id = localStorage.getItem("id");
  const handleFormSubmit = () => {
    const newErrors = {};
    
    if (!province) newErrors.province = "Vui lòng chọn Tỉnh/Thành phố.";
    if (!district) newErrors.district = "Vui lòng chọn Quận/Huyện.";
    if (!ward) newErrors.ward = "Vui lòng chọn Xã/Phường.";
    if (!permanentAddress) newErrors.permanentAddress = "Vui lòng nhập địa chỉ thường trú.";
    
    if (!currentProvince) newErrors.currentProvince = "Vui lòng chọn Tỉnh/Thành phố hiện tại.";
    if (!currentDistrict) newErrors.currentDistrict = "Vui lòng chọn Quận/Huyện hiện tại.";
    if (!currentWard) newErrors.currentWard = "Vui lòng chọn Xã/Phường hiện tại.";
    if (!currentAddress) newErrors.currentAddress = "Vui lòng nhập địa chỉ hiện tại.";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const dataToSubmit = {
        ...userInfo,
        permanent_province: province, 
        permanent_district: district, 
        permanent_ward: ward,     
        current_province: currentProvince,
        current_district: currentDistrict, 
        current_ward: currentWard,  
        permanent_address: permanentAddress,
        current_address: currentAddress,
        ip_address: ipAddress, 
      };
      const dataStatus = {
        permanent_province: province, 
        permanent_district: district, 
        permanent_ward: ward,     
        current_province: currentProvince,
        current_district: currentDistrict, 
        current_ward: currentWard,  
        permanent_address: permanentAddress,
        current_address: currentAddress,
        ip_address: ipAddress, 
      };
      setUserInfo(dataToSubmit);
      Residence_info(id, dataStatus)
      .then((response) => {
        if (response.data.success) {
          navigate(`/vpbank-creditcard/step4Screen${location.search}`);
        } else {
          console.error("Error saving starting info:", response.data.message);
        }
      })
      .catch((error) => console.error("Error saving starting info:", error));
    
      
    
    }
  };
  useEffect(() => {
    fetchCities()
      .then((response) => setProvinces(response.data))
      .catch((error) => console.error(error));

    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => setIpAddress(response.data.ip))
      .catch((error) => console.error("Error fetching IP address:", error));
  }, []);
  useEffect(() => {
 
    fetchCities()
      .then((response) => {
        setProvinces(response.data);
      })
      .catch((error) => console.error(error))
      
  }, []);

  const handleProvinceChange = (e) => {
    const selectedProvince = e.target.value;
    setProvince(selectedProvince);
    setLoadings(true);
    fetchDistricts(selectedProvince)
      .then((response) => {
        setPermanentDistricts(response.data);
        setPermanentWards([]); 
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));

  };

  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setDistrict(selectedDistrict);
    setLoadings(true);
    fetchWards(selectedDistrict)
      .then((response) => { 
        setPermanentWards(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));

  };

  const handleCurrentProvinceChange = (e) => {
    const selectedProvince = e.target.value;
    setCurrentProvince(selectedProvince);
    setLoadings(true);
    fetchDistricts(selectedProvince)
      .then((response) => {
        setCurrentDistricts(response.data);
        setCurrentWards([]);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  };

  const handleCurrentDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setCurrentDistrict(selectedDistrict);
    setLoadings(true);
    fetchWards(selectedDistrict)
      .then((response) => { 
        setCurrentWards(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));

  };

  const provincesArray = provinces.map((city) => (
    <option key={city.id} value={city.code}>
      {city.name}
    </option>
  ));

  const permanentDistrictsArray = Object.entries(permanentDistricts);
  const permanentWardsArray = Object.entries(permanentWards);
  const currentDistrictsArray = Object.entries(currentDistricts);
  const currentWardsArray = Object.entries(currentWards);

  return (
    <div className="h-[1350px] flex flex-col items-center p-4 bg-white">
        {loadings && <LoadingOverlay />}
      <HeaderRegister currentStep={currentStep} />
      <div className="mt-8 w-[100%] bg-[#850101] rounded-2xl shadow-lg p-4 custom-shadow">
        <h2 className="text-lg font-bold text-white uppercase text-center">
          Thông tin cư trú
        </h2>

        <div className="mt-6 flex justify-center items-center">
          <h3 className="text-base h-10 rounded-md w-[60%] bg-white font-medium text-center flex items-center justify-center">
            Địa chỉ thường trú
          </h3>
        </div>
        <div className="mt-5">
          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Tỉnh/Thành phố:
            </label>
            <select
              value={province}
              onChange={handleProvinceChange}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
            >
              <option value="" disabled>
                Chọn tỉnh/thành phố
              </option>
              {provincesArray}
            </select>
            {errors.province && <p className="text-red-500 text-xs">{errors.province}</p>}
          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Quận/Huyện:
            </label>
            <select
              value={district}
              onChange={handleDistrictChange}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              disabled={!province}
            >
              <option value="" disabled>
                Chọn quận/huyện
              </option>
              {permanentDistrictsArray.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </select>
            {errors.district && <p className="text-red-500 text-xs">{errors.district}</p>}
          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Xã/Phường:
            </label>
            <select
              value={ward}
              onChange={(e) => setWard(e.target.value)}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              disabled={!district}
            >
              <option value="" disabled>
                Chọn xã/phường
              </option>
              {permanentWardsArray.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </select>
            {errors.ward && <p className="text-red-500 text-xs">{errors.ward}</p>}

          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Địa chỉ chi tiết:
            </label>
            <input
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              placeholder="Nhập chi tiết địa chỉ thường trú"
              type="text"
              value={permanentAddress}
              onChange={(e) => setPermanentAddress(e.target.value)}
              maxLength={70}
            />
           {errors.permanentAddress && <p className="text-red-500 text-xs">{errors.permanentAddress}</p>}

          </div>
        </div>
        
        {/* Địa chỉ hiện tại */}
        <div className="flex justify-center mt-10 mb-10">
          <div className="border-t border-white w-full"></div>
        </div>

        <div className=" flex justify-center items-center ">
          <h3 className="text-base h-10 rounded-md w-[60%] bg-white font-medium text-center flex items-center justify-center">
            Địa chỉ hiện tại
          </h3>
        </div>
        <div className="mt-5">
          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Tỉnh/Thành phố:
            </label>
            <select
              value={currentProvince}
              onChange={handleCurrentProvinceChange}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
            >
              <option value="" disabled>
                Chọn tỉnh/thành phố
              </option>
              {provincesArray}
            </select>
            {errors.currentProvince && <p className="text-red-500 text-xs">{errors.currentProvince}</p>}

          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Quận/Huyện:
            </label>
            <select
              value={currentDistrict}
              onChange={handleCurrentDistrictChange}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              disabled={!currentProvince} 
            >
              <option value="" disabled>
                Chọn quận/huyện
              </option>
              {currentDistrictsArray.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </select>
            {errors.currentDistrict && <p className="text-red-500 text-xs">{errors.currentDistrict}</p>}

          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Xã/Phường:
            </label>
            <select
              value={currentWard}
              onChange={(e) => setCurrentWard(e.target.value)}
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              disabled={!currentDistrict} 
            >
              <option value="" disabled>
                Chọn xã/phường
              </option>
              {currentWardsArray.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </select>
            {errors.currentWard && <p className="text-red-500 text-xs">{errors.currentWard}</p>}

          </div>

          <div className="mb-4">
            <label className="block text-white text-sm font-normal ml-2 mb-1">
              Địa chỉ chi tiết:
            </label>
            <input
              className="h-10 rounded-md text-sm px-2 bg-white w-full"
              placeholder="Nhập chi tiết địa chỉ hiện tại"
              type="text"
              value={currentAddress}
              onChange={(e) => setCurrentAddress(e.target.value)}
              maxLength={70}
            />
           {errors.currentAddress && <p className="text-red-500 text-xs">{errors.currentAddress}</p>}

          </div>
        </div>
      </div>
      <div className="flex justify-center mt-6 mb-8">
        <button
          onClick={handleFormSubmit}
          className="bg-gradient-to-r from-red-900 to-red-700 rounded-[17px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"        >
        
          <span className="text-white text-base font-semibold">Tiếp tục</span>
        </button>
      </div>
    </div>
  );
};

export default Step3Screen;
