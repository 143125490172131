
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;



export const fetchCities = async () => {
  return axios.get(`${API_URL}/get-cities`);
};

export const fetchDistricts = async (provinceCode) => {
  return axios.get(`${API_URL}/get-districts/${provinceCode}`);
};

export const fetchWards = async (provinceCode) => {
  return axios.get(`${API_URL}/get-wards/${provinceCode}`);
};
