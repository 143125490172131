import React, { useState, useRef, useEffect } from "react";
import { useUserContext } from "../UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingOverlay from "../components/Loading";
import { assignOtp, verifyOtp } from "../services/apiService";
import { confirmEcontract } from "../services/apiService"; 
import "./otp.css";
import Swal from "sweetalert2";

const OTPScreencf = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputsRef = useRef([]);
  const [timeLeft, setTimeLeft] = useState(180);
  const { userInfo } = useUserContext();
  const [loadings, setLoadings] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [otpNumber, setOtpNumber] = useState(null);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const id = localStorage.getItem("id");
  const userId = localStorage.getItem("userId");
  const phoneStep = localStorage.getItem("phoneStep");
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10000); 

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    setShowNotification(true);
    const timer = setTimeout(() => setShowNotification(false), 4000);
    return () => clearTimeout(timer);
  }, []);
  const phoneFinal = userInfo.phone_number ? userInfo.phone_number : phoneStep;
  useEffect(() => {
    const fetchOtp = async () => {
      try {
        setLoadings(true);
        const response = await assignOtp({
          phone_number: phoneFinal,
        });
        if (response.data.data.assignOTPResult) {
          const otpString = response.data.data.assignOTPResult.toString();
          const otpArray = otpString.split("");
          setSessionId(response.data.data.sessionId);
          setOtpNumber(response.data.data.assignOTPResult);
          setOtp(otpArray);
        }
      } catch (error) {
        console.error("Error assigning OTP:", error);
      } finally {
        setLoadings(false);
      }
    };
    fetchOtp();
  }, [userInfo.phone_number]);

  const handleChange = (text, index) => {
    if (/^[0-9]$/.test(text) || text === "") {
      const newOtp = [...otp];
      newOtp[index] = text;
      setOtp(newOtp);

      if (text.length === 1 && index < otp.length - 1) {
        inputsRef.current[index + 1].focus();
      }
    }
  };
  const handleVerifyOtp = async () => {
    const finalId = id !== null ? id : userId;

    const otp_number = otp.join("");
    try {
      setLoadings(true);
      const otpAsString = String(otp_number);

      const response = await verifyOtp({
        phone_number: phoneFinal,
        otp_number: otpAsString,
        sessionId: sessionId,
      });
      if (response.data.status === "success") {
        await confirmEcontract(finalId);
        navigate(`/vpbank-creditcard/waitingresult${location.search}`);
        Swal.fire({
          icon: "success",
          title: "Xác thực OTP thành công",
          text: "Đang chuyển trang...",
          background: "#fff",
          timer: 1600,
        });
      } else {
        setError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError("An error occurred during OTP verification.");
    } finally {
      setLoadings(false);
    }
  };
  const handleResendOtp = async () => {
    try {
      setLoadings(true);
      const response = await assignOtp({
        phone_number: phoneFinal,
      });
      if (response.data.data.assignOTPResult) {
        const otpString = response.data.data.assignOTPResult.toString();
        const otpArray = otpString.split("");
        setSessionId(response.data.data.sessionId);
        setOtpNumber(response.data.data.assignOTPResult);
        setOtp(otpArray);
        setTimeLeft(180);
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    } finally {
      setLoadings(false);
    }
  };

  const isComplete = otp.every((digit) => digit !== "");

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  return (
    <div className="flex flex-col mb-10  p-4 bg-white">
      {loadings && <LoadingOverlay />}
      <h1 className="text-xl font-extrabold text-center mt-2 text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-500">
        Đăng ký ngay, nhận thẻ liền tay
      </h1>
      <div className="text-center  mt-2 ">
        <p className="text-xs font-normal">
          Vui lòng nhập mã sos OTP được gửi về số điện thoại
        </p>
      </div>
      <div className="flex flex-row  items-center justify-center mt-8 w-4/5 mx-auto gap-1">
        {otp.map((digit, index) => (
          <input
            key={index}
            className="h-12 w-12 bg-gray-200 rounded text-center font-bold text-lg"
            type="number"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(e.target.value, index)}
            ref={(ref) => (inputsRef.current[index] = ref)}
          />
        ))}
      </div>

      <p className="text-center text-sm text-gray-600 mt-6">
        Mã OTP sẽ hết hạn trong vòng{" "}
        <span className="text-green-500 font-bold">{timeLeft} giây</span>{" "}
      </p>

      <div className="flex  items-center justify-center">
      {isVisible && (
        <div className="flex mt-4 items-center justify-center">
          <button
            className="bg-gradient-to-r from-gray-200 to-gray-200 text-black rounded-[8px] shadow-lg w-32 h-8 flex items-center justify-center font-semibold text-xs transition-transform duration-300 hover:scale-105 active:scale-95"
            onClick={handleResendOtp}
          >
            Gửi Lại
          </button>
        </div>
      )}
    </div>
      {isComplete && (
        <div className="flex items-center justify-center mt-6 mb-10">
          <button
            className="bg-gradient-to-r from-red-900 to-red-700 rounded-[10px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"
            onClick={handleVerifyOtp}
          >
            <p className="text-white text-base font-semibold">Tiếp tục</p>
          </button>
        </div>
      )}

      {error && <p className="text-center text-red-500 mt-2">{error}</p>}
      <div className="ml-4 mt-4">
        <p className="text-left text-sm text-gray-900 mt-2">Lưu ý:</p>
        <div className="text-xs">
          <p className="text-left text-xs text-gray-500 mt-1 font-light">
            • Bạn chỉ nhập mã OTP tối đa 5 lần.
          </p>
          <p className="text-left text-xs text-gray-500 mt-1 font-light">
            • Phiên làm việc sẽ tạm ngưng 30 phút nếu gửi lại OTP quá 5 lần.
          </p>
          <p className="text-left text-xs text-gray-500 mt-1 font-light">
            • Bằng việc tiếp tục, tôi đồng ý để VPBank xử lý dữ liệu.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OTPScreencf;
