import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingOverlay from "../components/Loading1";
import { getLead } from "../services/apiService";
const WaitingResult = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loadings] = useState(true);
  const id = localStorage.getItem("id");
  const userId = localStorage.getItem("userId");

  const finalId = id !== null ? id : userId;

  useEffect(() => {
    if (finalId) {
      const interval = setInterval(() => {
        getLead(finalId)
          .then((response) => {
            const { status } = response.data.data;

            if (status === "CardIssued") {
              clearInterval(interval);
              navigate(`/vpbank-creditcard/complete${location.search}`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [finalId, location.search, navigate]);
  return (
    <div className="flex flex-col items-center p-4 bg-white mb-[130px]">
      <div className="w-full text-left mt-2 ml-10">
        <p className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-500">
          Đợi kết quả
        </p>
        <p className="text-[12px] font-light">
          Kết quả sẽ có sau ít phút
        </p>
      </div>
      <div className="top-8 p-3 mt-8 bg-[#850101] text-white transform rounded-[15px] text-center items-center w-full max-w-md custom-shadow">
        <div className="text-xs font-bold ml-3 mt-6 ">
          Hồ sơ của bạn đang được phê duyệt
        </div>
        <div className="text-xs  ml-3 mt-2 ">Vui lòng chờ trong giây lát</div>
        <div className="flex justify-center mt-4 mb-[30px]">
          <img
            id="frontImage"
            src={require("../assets/waiting.png")}
            alt="image gr37"
            className="w-[18%]"
          />
        </div>
        {loadings && <LoadingOverlay />}
      </div>
    </div>
  );
};

export default WaitingResult;
